import React, { Fragment, useState } from "react";
import { Form, Modal, Button, Spinner } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import MsgBox from "../chatBox/MsgBox";
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";
import avatar4 from "../../../images/avatar/4.jpg";
import avatar5 from "../../../images/avatar/5.jpg";
import MobileSlider from "./MobileSlider";

const apiUrl = `https://qui-ws.azurewebsites.net/api`;

const CreateModule = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [lessons, setLessons] = useState({});
  const [lessonCount, setLessonCount] = useState(1);
  const [openMsg, setOpenMsg] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    const token = Cookies.get("jwtToken");
    e.preventDefault();
    setLoading(true); // Set loading to true when submission starts
    try {
      const organizedContent = Object.keys(lessons).map((lessonId) => ({
        title: lessons[lessonId].title,
        content: lessons[lessonId].content,
      }));
      const response = await axios.post(
        `${apiUrl}/modules`,
        {
          title,
          description,
          content: organizedContent,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response.data);
      setShowModal(true);
    } catch (error) {
      console.error("Error creating module:", error);
    } finally {
      setLoading(false); // Set loading to false when submission is complete
    }
  };

  const handleAddLesson = () => {
    const newLessonId = `lesson-${lessonCount}`;
    setLessonCount(lessonCount + 1);
    setLessons({
      ...lessons,
      [newLessonId]: { title: "", content: "" },
    });
  };

  const handleLessonTitleChange = (e, lessonId) => {
    setLessons({
      ...lessons,
      [lessonId]: {
        ...lessons[lessonId],
        title: e.target.value,
      },
    });
  };

  const handleLessonContentChange = (data, lessonId) => {
    setLessons({
      ...lessons,
      [lessonId]: {
        ...lessons[lessonId],
        content: data,
      },
    });
  };

  const getCloseAlert = () => {
    setShowModal(false);
    navigate("/modules");
  };

  return (
    <Fragment>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Module has been created successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={getCloseAlert}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div
          className="col-xl-12 d-flex flex-row justify-content-start"
          style={{ height: "620px" }}
        >
          {/* <div className="card col-xl-8">
            <div
              className="card-body"
              style={{ height: "100px", paddingBottom: "10px" }}
            >
              <div className="row">
                <div
                  className="col-md-12 order-md-1 overflow-scroll"
                  style={{
                    height: "75vh",
                    overflowY: "auto",
                    paddingBottom: "10px",
                  }}
                >
                  <h4 className="mb-3">Create Module</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Module Title"
                        required
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Module Description"
                        required
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>

                    <div className="mb-3">
                      <label className="form-label">Lessons</label>
                      {Object.keys(lessons).map((lessonKey) => (
                        <div key={lessonKey} className="mb-1">
                          <h5>{lessonKey}</h5>
                          <input
                            type="text"
                            className="form-control mb-2"
                            placeholder="Enter lesson title"
                            value={lessons[lessonKey]?.title || ""}
                            onChange={(e) =>
                              handleLessonTitleChange(e, lessonKey)
                            }
                          />
                          <label htmlFor="content" className="form-label">
                            Content
                          </label>
     


                          <div
                            style={{
                              height: "300px", // Set the fixed height of the CKEditor container
                              overflowY: "auto", // Enable vertical scrolling for content overflow
                              border: "1px solid #ccc", // Add a light border around the editor
                              borderRadius: "4px", // Slightly round the border corners
                              padding: "10px", // Add padding around the editor
                            }}
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                toolbar: [
                                  "bold",
                                  "italic",
                                  "bulletedList",
                                  "numberedList",
                                  "|",
                                  "link",
                                  "undo",
                                  "redo",
                                ],
                              }}
                              onReady={(editor) => {
                                // Set inline styles for the editable area inside the CKEditor
                                const editorContainer =
                                editor.ui.view.editable.element;
                                editorContainer.style.height = "100%";
                                editorContainer.style.overflowY = "auto";
                              }}
                              data={lessons[lessonKey]?.content || "<p>Type your content here...</p>"}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                handleLessonContentChange(data, lessonKey);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>

                    <button
                      type="button"
                      className="btn btn-secondary btn-sm mb-3"
                      onClick={handleAddLesson}
                    >
                      Add New Lesson
                    </button>

                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      disabled={loading} // Disable button when loading
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Create"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div> */}

          <MobileSlider/>
          <div className="m-2 tab-pane fade active show " role="tabpanel">
            <MsgBox
              avatar1={avatar1}
              avatar2={avatar2}
              openMsg={openMsg}
              // PerfectScrollbar={PerfectScrollbar}
              offMsg={() => setOpenMsg(false)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateModule;
