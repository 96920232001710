import { React, useMemo, useState, useEffect } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form, Alert, Modal, Button } from "react-bootstrap";
import { Row, Col, Card, Table, ProgressBar } from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import MOCK_DATA from "../table/FilteringTable/MOCK_DATA_2.json";
import { COLUMNS } from "../table/FilteringTable/Columns";
import { GlobalFilter } from "../table/FilteringTable/GlobalFilter";
import { ToastContainer, toast } from "react-toastify";
import Cookies from "js-cookie";
import { Spinner } from "react-bootstrap";

// import "./filtering.css";

const svg1 = (
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24" />
      <circle fill="#000000" cx="5" cy="12" r="2" />
      <circle fill="#000000" cx="12" cy="12" r="2" />
      <circle fill="#000000" cx="19" cy="12" r="2" />
    </g>
  </svg>
);

const Quizes = () => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => MOCK_DATA, []);
  const [showModal, setShowModal] = useState(false);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const [counts, setCounts] = useState({
    totalQuizzes: 0,
    activeQuizzes: 0,
    upcomingQuizzes: 0,
    completedQuizzes: 0,
  });
  const [quizzesData, setQuizzesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState(null);
  const [editStatus, setEditStatus] = useState("");

  const [modulesData, setModulesData] = useState([]);
  // const apiUrl = "http://localhost:3000/api/quizzes";
  // const apiUrl2 = "http://localhost:3000";

  const apiUrl = 'https://qui-ws.azurewebsites.net/api/quizzes';
  const apiUrl2 = 'https://qui-ws.azurewebsites.net';

  const getCloseAlert = () => {
    setShowModal(false);
  };

  // Function to fetch data from the API
  // const fetchModulesData = async () => {
  //   const jwtToken = document.cookie
  //     .split("; ")
  //     .find((row) => row.startsWith("jwtToken="))
  //     ?.split("=")[1];

  //   if (!jwtToken) {
  //     console.error("Authorization token not found in cookies.");
  //     return;
  //   }

  //   try {
  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: `Bearer ${jwtToken}`,
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     setModulesData(data); // Store the response in the state
  //   } catch (error) {
  //     console.error("Error fetching module data:", error);
  //   }
  // };
  const fetchModulesData = async () => {
    const jwtToken = Cookies.get("jwtToken");

    if (!jwtToken) {
        console.error("Authorization token not found in cookies.");
        return;
    }

    try {
        const response = await fetch(`${apiUrl2}/api/quizzes`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${jwtToken}`,
            },
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setQuizzesData(data); // Store the response in the state
    } catch (error) {
        console.error("Error fetching quiz data:", error);
    } finally {
        setLoading(false);
    }
};
  const handleDelete = async (quizId) => {
    console.log("Delete button is clicked");
    const jwtToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwtToken="))
      ?.split("=")[1];

    if (!jwtToken) {
      console.error("Authorization token not found in cookies.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/${quizId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        console.log(response.status);
      } else {
        console.log(response);
      }

      // Remove the deleted module from the state
      setModulesData(modulesData.filter((module) => module.quizId !== quizId));
      setShowModal(true);
    } catch (error) {
      console.error("Error deleting module:", error);
      alert("Failed to delete module. Please try again.");
    }
  };
  const fetchCounts = async () => {
    try {
      const jwtToken = Cookies.get("jwtToken");
      if (!jwtToken) {
        throw new Error("JWT token not found");
      }

      const response = await fetch(
        `${apiUrl2}/api/admin/analytics/user-counts`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      const data = await response.json();
      setCounts({
        totalQuizzes: data.quizCount,
        activeQuizzes: data.quizActiveCount,
        upcomingQuizzes: data.quizHoldCount,
        completedQuizzes: data.quizClosedCount,
      });
    } catch (error) {
      console.error(error);
      toast.error("Error fetching counts data");
    } finally {
      setLoading(false);
    }
  };
  const handleEdit = (quiz) => {
    setCurrentQuiz(quiz);
    setEditStatus(quiz.status);
    setShowEditModal(true);
};

const handleSave = async () => {
    const jwtToken = Cookies.get("jwtToken");
    if (!jwtToken) {
        console.error("JWT token not found");
        return;
    }

    try {
        const response = await fetch(`${apiUrl2}/api/quizzes/${currentQuiz.quizId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`,
            },
            body: JSON.stringify({ status: editStatus }),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
        }

        const updatedQuiz = await response.json();
        setQuizzesData(quizzesData.map(quiz => quiz.quizId === updatedQuiz.quizId ? updatedQuiz : quiz));
        setShowEditModal(false);
        toast.success("Quiz updated successfully");
    } catch (error) {
        console.error("Error updating quiz:", error);
        toast.error("Error updating quiz");
    }
};

  // Fetch data when the component mounts
  useEffect(() => {
    fetchModulesData();
    fetchCounts();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  const [openModal, setOpenModal] = useState();
  const [showAlert, setShowAlert] = useState(false);
  return (
    <div className="container">
      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Quiz has been Delted successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={getCloseAlert}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="form-head d-flex mb-3 mb-lg-5 align-items-start flex-wrap"></div> */}
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            <div className="row" style={{ marginLeft: "1px" }}>
              <Link
                to={"/create-quiz"}
                className="btn btn-success ms-auto px-5 mb-4"
                onClick={() => setOpenModal(true)}
              >
                + Add New Quiz
              </Link>
            </div>

            {/* Card 1 */}
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-primary text-primary">
                      <svg
                        id="icon-customers"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-user"
                      >
                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                        <circle cx="12" cy="7" r="4"></circle>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">TOTAL QUIZES</p>
                      {/* <h4 className="mb-0">{counts.totalQuizzes}</h4> */}
                      {loading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        counts.totalQuizzes
                      )}
                      {/* <span className="badge badge-primary">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-success text-success">
                      <svg
                        id="icon-database-widget"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-database"
                      >
                        <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                        <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                        <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">ACTIVE QUIZES</p>
                      {loading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        counts.activeQuizzes
                      )}{" "}
                      {/* <span className="badge badge-success">-3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-warning text-warning">
                      <svg
                        id="icon-orders"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-file-text"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                        <polyline points="14 2 14 8 20 8"></polyline>
                        <line x1="16" y1="13" x2="8" y2="13"></line>
                        <line x1="16" y1="17" x2="8" y2="17"></line>
                        <polyline points="10 9 9 9 8 9"></polyline>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">UPCOMING QUIZES</p>
                      {loading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        counts.upcomingQuizzes
                      )}
                      {/* <span className="badge badge-warning">+3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6">
              <div className="widget-stat card">
                <div className="card-body  p-4">
                  <div className="media ai-icon">
                    <span className="me-3 bgl-danger text-danger">
                      <svg
                        id="icon-revenue"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-dollar-sign"
                      >
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg>
                    </span>
                    <div className="media-body">
                      <p className="mb-1">COMPLETED QUIZES</p>
                      {loading ? (
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      ) : (
                        counts.completedQuizzes
                      )}
                      {/* <span className="badge badge-danger">-3.5%</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side - Todo list */}
      </div>
      <Col lg={12}>
            <Card>
                <Card.Header>
                    <Card.Title>Recent Quizzes</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th className="width50">
                                    <strong>S.No</strong>
                                </th>
                                <th>
                                    <strong> Id</strong>
                                </th>
                                <th>
                                    <strong> Name</strong>
                                </th>
                                <th>
                                    <strong> Linked Module</strong>
                                </th>
                                <th>
                                    <strong> Status</strong>
                                </th>
                                <th>
                                    <strong> Action</strong>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {quizzesData.length > 0 ? (
                                quizzesData.map((quiz, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <strong>{index + 1}</strong> {/* S.No Column */}
                                            </td>
                                            <td>
                                                <strong>{quiz.quizId || quiz._id}</strong>
                                            </td>
                                            <td>{quiz.title}</td>
                                            <td>{quiz.linkedModuleId}</td> {/* Display linked module */}
                                            <td>
                                                <span
                                                    className={`badge light badge-${
                                                        quiz.status === "active" ? "success" : "warning"
                                                    }`}
                                                >
                                                    {quiz.status}
                                                </span>
                                            </td>
                                            <td>
                                                <Dropdown>
                                                    <Dropdown.Toggle
                                                        variant="success"
                                                        className="light sharp i-false"
                                                    >
                                                        &#x25BC;
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => handleEdit(quiz)}>Edit</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleDelete(quiz.quizId)}>Delete</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                              <tr>
                              <td colSpan="6" className="text-center">
                                <Spinner animation="border" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </Spinner>
                              </td>
                            </tr>

                                // <tr>
                                //     <td colSpan="6">No quizzes available</td>
                                // </tr>
                            )}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>

            {/* Edit Modal */}
            <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Quiz</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                value={editStatus}
                                onChange={(e) => setEditStatus(e.target.value)}
                            >
                                <option value="active">Active</option>
                                <option value="hold">Hold</option>
                                <option value="closed">Closed</option>                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer />
        </Col>

    </div>
  );
};

export default Quizes;
