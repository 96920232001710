import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://qui-ws.azurewebsites.net/api`

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handlePhone = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const nav = useNavigate();

  // Redirect user to the home page if they are already logged in
  useEffect(() => {
    const jwtToken = Cookies.get("jwtToken");
    if (jwtToken) {
      nav("/"); // Redirect to the home page
    }
  }, [nav]);

  // Toast notification function
  const notifyTopCenter = () => {
    toast.error("Invalid credentials.", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const phoneNumber = email;
      const response = await fetch(`${apiUrl}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber, password }),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        Cookies.set("jwtToken", token, { expires: 30 });
        nav("/"); // Navigate to the home page after successful login
      } else {
        // Show a warning toast on login failure
        notifyTopCenter();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error during login:", error);
    }
  };

  return (
    <div className="authincation">
      <div className="container">
        <ToastContainer /> {/* To render the toast notification */}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="mb-2 text-center">
                      <img
                        src="https://res.cloudinary.com/dbylngblb/image/upload/v1727508953/logo_wfnqks.svg"
                        style={{ alignSelf: "center" }}
                        alt=""
                      />
                    </div>
                    <h4 className="text-center mb-4">Sign in your account</h4>

                    <form action="" onSubmit={(e) => submitHandler(e)}>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>EMPLOYEE ID</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Your Employee ID"
                          name="Email"
                          onChange={handlePhone}
                        />
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Enter Your Password"
                          name="password"
                          onChange={handlePassword}
                        />
                      </div>
                      <div className="form-row d-flex justify-content-between mt-4 mb-2">
                        <div className="form-group">
                          <div className="custom-control custom-checkbox ms-1">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="basic_checkbox_1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="basic_checkbox_1"
                            >
                              Remember my password
                            </label>
                          </div>
                        </div>
                        {/* <div className="form-group">
                          <Link to="/page-forgot-password">
                            Forgot Password?
                          </Link>
                        </div> */}
                      </div>
                      <div className="text-center d-flex align-items-center justify-content-center">
                        {loading ? (                          <div className="spinner  "></div>
                        ) : (                          <input
                            type="submit"
                            value="Sign Me In"
                            className="btn btn-primary btn-block"
                          />
                        )}
                      </div>
                    </form>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
