import React, { useState, useContext, useEffect } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { Row, Col, Card, Button, ButtonGroup } from "react-bootstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RecoveredChart = loadable(() =>
  pMinDelay(import("./charts/home/RecoveredChart"), 1000)
);

const VisitorChart = loadable(() =>
  pMinDelay(import("./charts/home/VisitorChart"), 1000)
);
const ChartCircle = loadable(() =>
  pMinDelay(import("./charts/home/ChartCircle"), 1000)
);
const RevenueChart = loadable(() =>
  pMinDelay(import("./charts/home/RevenueChart"), 1000)
);

// const apiUrl = `http://localhost:3000`;
const apiurl = `https://qui-ws.azurewebsites.net`;

const Home = () => {
  //announcements

  const notifySuccess = () => {
    toast.info("Announcement added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyError = () => {
    toast.error("Failed to add announcement!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Col lg="12">
        <div className="card-body d-flex justify-content-end">
          <Button className="me-2" variant="secondary btn-rounded">
            <span className="btn-icon-start text-secondary">
              <i className="fa fa-file-pdf color-secondary" />{" "}
            </span>
            EXPORT PDF
          </Button>
          <Button variant="success btn-rounded">
            <span className="btn-icon-start text-success">
              <i className="fa-solid fa-file-excel color-success" />
            </span>
            EXPORT EXCEL
          </Button>
        </div>
      </Col>
      <div className="row">
        <div className="col-xl-6 col-xxl-12">
          <div className="row">
            <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Weekly Report Graph</h4>
                  <Dropdown className="ms-auto no-carat">
                    <Dropdown.Toggle
                      as="div"
                      variant=""
                      id="dropdown-basic"
                      className="p-0 i-false btn-link"
                    >
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item>Edit</Dropdown.Item>
                      <Dropdown.Item>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pb-0">
                  <div className="recovered-chart-deta d-flex flex-wrap">
                    <div className="col mb-3">
                      <span className="bg-danger"></span>
                      <div>
                        <p>Inactive Nurses</p>
                        <h5>53</h5>
                      </div>
                    </div>
                    <div className="col mb-3">
                      <span className="bg-success"></span>
                      <div>
                        <p>Active Nurses</p>
                        <h5>230</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0 px-3 pb-0">
                  <RecoveredChart />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Overall Learning Report</h4>
                  <Dropdown className="ms-auto no-carat">
                    <Dropdown.Toggle
                      variant=""
                      as="div"
                      id="dropdown-basic"
                      className="p-0 i-false btn-link"
                    >
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item>Edit</Dropdown.Item>
                      <Dropdown.Item>Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body px-3 pt-2 pb-0">
                  <div id="chartStrock">
                    <VisitorChart />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Nurse Learning (%)</h4>
                  <Form.Select className="select-button  style-1 default-select">
                    <option value="1">Daily</option>
                    <option value="2">Weekly</option>
                    <option value="3">Monthly</option>
                  </Form.Select>
                </div>
                <div className="card-body pt-2">
                  <h4 className="text-dark font-w400">Nurse Score Analytics</h4>
                  <h3 className="text-primary font-w600">102 People</h3>
                  <div className="row mx-0 align-items-center">
                    <div className="col-sm-8 col-md-7 px-0">
                      <div id="chartCircle">
                        <ChartCircle />
                      </div>
                    </div>
                    <div className="col-sm-4 col-md-5 px-0">
                      <div className="patients-chart-deta">
                        <div className="col px-0">
                          <span className="bg-danger"></span>
                          <div>
                            <p>Failed</p>
                            <h3>30%</h3>
                          </div>
                        </div>
                        <div className="col px-0">
                          <span className="bg-success"></span>
                          <div>
                            <p>Outsanding Score</p>
                            <h3>73%</h3>
                          </div>
                        </div>
                        <div className="col px-0">
                          <span className="bg-warning"></span>
                          <div>
                            <p>Pass Score</p>
                            <h3>48%</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-xxl-12">
          <div className="row">
            <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Module Analytics</h4>
                  <Form.Select className="select-button  style-1 default-select">
                    <option value="1">Module 1</option>
                    <option value="2">Module 2</option>
                    <option value="3">Module 3</option>
                  </Form.Select>
                </div>
                <div className="card-body pt-2">
                  <h3 className="text-primary font-w600">
                    Nurse Learners <small className="text-dark ms-2"></small>
                  </h3>
                  <div id="chartBar">
                    <RevenueChart />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">{/* <Timeline /> */}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
