import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import PageTitle from '../../../layouts/PageTitle';
import Profilt from './../../../../images/avatar/1.png';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';

// const apiUrl = 'http://localhost:3000';
const apiUrl = 'https://qui-ws.azurewebsites.net';

const options2 = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
];
const options3 = [
    { value: 'Russia', label: 'Russia' },
    { value: 'Canada', label: 'Canada' },
    { value: 'China', label: 'China' },
    { value: 'India', label: 'India' }
];
const options4 = [
    { value: 'Krasnodar', label: 'Krasnodar' },
    { value: 'Tyumen', label: 'Tyumen' },
    { value: 'Chelyabinsk', label: 'Chelyabinsk' },
    { value: 'Moscow', label: 'Moscow' }
];

const EditProfile = () => {
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        specialty: '',
        skills: '',
        gender: '',
        birth: '',
        phone: '',
        email: '',
        country: '',
        city: ''
    });

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const jwtToken = Cookies.get("jwtToken");
                if (!jwtToken) {
                    throw new Error("JWT token not found");
                }

                const response = await fetch(`${apiUrl}/api/user`, {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                        Authorization: `Bearer ${jwtToken}`
                    }
                });

                if (!response.ok) throw new Error(`Error: ${response.statusText}`);

                const user = await response.json();
                setUserData({
                    firstName: user.firstName,
                    lastName: user.lastName,
                    specialty: "Instructor", // Assuming specialty is static
                    skills: "", // Assuming skills are static
                    gender: user.gender || '',
                    birth: '', // Assuming birth date is not provided
                    phone: user.phoneNumber,
                    email: user.email,
                    country: '', // Assuming country is not provided
                    city: '' // Assuming city is not provided
                });
            } catch (error) {
                console.error(error);
                toast.error("Error fetching user data");
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSelectChange = (name, selectedOption) => {
        setUserData(prevState => ({
            ...prevState,
            [name]: selectedOption.value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic
        console.log(userData);
    };

    return (
        <>
            <PageTitle activeMenu="Edit Profile" motherMenu="App" />
            <div className="row">
                <div className="col-xl-3 col-lg-4">
                    <div className="clearfix">
                        <div className="card card-bx profile-card author-profile mb-3">
                            <div className="card-body">
                                <div className="p-5">
                                    <div className="author-profile">
                                        <div className="author-media">
                                            <img src={Profilt} alt="" />
                                            <div className="upload-link" title="" data-toggle="tooltip">
                                                <input type="file" className="update-flie" />
                                                <i className="fa fa-camera"></i>
                                            </div>
                                        </div>
                                        <div className="author-info">
                                            <h6 className="title">{userData.firstName} {userData.lastName}</h6>
                                            <span>{userData.specialty}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-list">
                                    <ul>
                                        <li><Link to={"/app-profile"}>Modules</Link><span>36</span></li>
                                        <li><Link to={"/uc-lightgallery"}>Quizes</Link><span>3</span></li>
                                        <li><Link to={"/app-profile"}>Points</Link><span>1</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="input-group mb-3">
                                    <div className="form-control rounded text-center bg-white">Portfolio</div>
                                </div>
                                <div className="input-group">
                                    <Link to="https://www.apolllo.com/" target="blank" className="form-control text-primary rounded text-start bg-white">https://www.apolllo.com/</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-9 col-lg-8">
                    <div className="card profile-card card-bx mb-3">
                        <div className="card-header">
                            <h6 className="title">Account setup</h6>
                        </div>
                        <form className="profile-form" onSubmit={handleSubmit}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">First Name</label>
                                        <input type="text" className="form-control" name="firstName" value={userData.firstName} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Last Name</label>
                                        <input type="text" className="form-control" name="lastName" value={userData.lastName} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Specialty</label>
                                        <input type="text" className="form-control" name="specialty" value={userData.specialty} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Skills</label>
                                        <input type="text" className="form-control" name="skills" value={userData.skills} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Gender</label>
                                        <Select 
                                            options={options2} 
                                            className="custom-react-select" 
                                            value={options2.find(option => option.value === userData.gender)}
                                            onChange={(selectedOption) => handleSelectChange('gender', selectedOption)}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Birth</label>
                                        <input type="text" className="form-control" name="birth" value={userData.birth} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Phone</label>
                                        <input type="text" className="form-control" name="phone" value={userData.phone} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Email address</label>
                                        <input type="text" className="form-control" name="email" value={userData.email} onChange={handleChange} />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">Country</label>
                                        <Select 
                                            options={options3} 
                                            className="custom-react-select" 
                                            value={options3.find(option => option.value === userData.country)}
                                            onChange={(selectedOption) => handleSelectChange('country', selectedOption)}
                                            isSearchable={false}
                                        />
                                    </div>
                                    <div className="col-sm-6 mb-3">
                                        <label className="form-label">City</label>
                                        <Select 
                                            options={options4} 
                                            className="custom-react-select" 
                                            value={options4.find(option => option.value === userData.city)}
                                            onChange={(selectedOption) => handleSelectChange('city', selectedOption)}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer align-items-center d-flex justify-content-between">
                                <button className="btn btn-primary" type="submit">UPDATE</button>
                                <Link to={"#"} className="btn-link">Forgot your password?</Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditProfile;
