import React, { useState, useContext, useEffect } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown, Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import Timeline from "./Timeline";
import { ThemeContext } from "../../../context/ThemeContext";

//Images
// import avatar5 from "../../../images/avatar/6.jpg";

import Calendar from "react-calendar";
//TOAST
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RecoveredChart = loadable(() =>
  pMinDelay(import("./charts/home/RecoveredChart"), 1000)
);

const VisitorChart = loadable(() =>
  pMinDelay(import("./charts/home/VisitorChart"), 1000)
);
const ChartCircle = loadable(() =>
  pMinDelay(import("./charts/home/ChartCircle"), 1000)
);
const RevenueChart = loadable(() =>
  pMinDelay(import("./charts/home/RevenueChart"), 1000)
);


// const apiUrl = `http://localhost:3000`;
const apiUrl = `https://qui-ws.azurewebsites.net`

const Home = () => {
  const [active, setActive] = useState("3");
  const { changeBackground } = useContext(ThemeContext);
  const [value, onChange] = useState(new Date());
  const [userCounts, setUserCounts] = useState({
    totalUsers: 0,
    nurseCount: 0,
    instructorCount: 0,
    adminCount: 0,
    moduleCount: 0,
    quizCount: 0,
  });

  //announcements
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchAnnouncements = async () => {
    try {
      setLoading(true);
      const jwtToken = Cookies.get("jwtToken");

      if (!jwtToken) {
        throw new Error("JWT token not found in cookies");
      }

      const response = await fetch(`${apiUrl}/api/announcements`, {
        method: "GET",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setAnnouncements(data);
    } catch (error) {
      console.error("Failed to fetch announcements:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAnnouncement = () => {
    Swal.fire({
      title: "Enter Announcement Message",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,
      preConfirm: async (text) => {
        if (!text) {
          Swal.showValidationMessage("Message cannot be empty");
          return;
        }

        try {
          const jwtToken = Cookies.get("jwtToken");
          const response = await fetch(`${apiUrl}/api/announcements`, {
            method: "POST",
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ text }),
          });

          if (!response.ok) throw new Error("Failed to add announcement");

          const newAnnouncement = await response.json();
          setAnnouncements((prev) => [newAnnouncement, ...prev]); // Add new announcement to top of the list
          notifySuccess("✅ Announcement added successfully!");
        } catch (error) {
          notifyError();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleDelete = async (id) => {
    try {
      const jwtToken = Cookies.get("jwtToken");

      if (jwtToken) {
        const response = await fetch(`${apiUrl}/api/announcements/${id}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });

        if (response.ok) {
          // Remove announcement from state
          setAnnouncements((prev) =>
            prev.filter((announcement) => announcement._id !== id)
          );
          notifySuccess("✅ Announcement deleted successfully!");
        }
      }
    } catch (error) {
      console.error("Failed to delete announcement:", error);
    }
  };

  const handleEdit = (id, currentText) => {
    Swal.fire({
      title: "Edit Announcement",
      input: "text",
      inputValue: currentText,
      showCancelButton: true,
      confirmButtonText: "Save",
      showLoaderOnConfirm: true,
      preConfirm: async (text) => {
        if (!text) {
          Swal.showValidationMessage("Message cannot be empty");
          return;
        }

        try {
          const jwtToken = Cookies.get("jwtToken");
          const response = await fetch(`${apiUrl}/api/announcements/${id}`, {
            method: "PUT",
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ text }),
          });

          if (!response.ok) throw new Error("Failed to edit announcement");

          // Update announcement in the state
          setAnnouncements((prev) =>
            prev.map((announcement) =>
              announcement._id === id ? { ...announcement, text } : announcement
            )
          );
          notifySuccess("✅ Announcement updated successfully!");
        } catch (error) {
          notifyError();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  useEffect(() => {
    const fetchUserCounts = async () => {
      try {
        // Get the JWT token from cookies
        const jwtToken = Cookies.get("jwtToken");

        if (!jwtToken) {
          throw new Error("JWT token not found in cookies");
        }

        const response = await fetch(
          `${apiUrl}/api/admin/analytics/user-counts`,
          {
            method: "GET",
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        setUserCounts(data);
        console.log(data);
      } catch (error) {
        console.error("Failed to fetch user counts:", error);
      }
    };

    fetchUserCounts();
    fetchAnnouncements();
  }, []);

  const notifySuccess = () => {
    toast.info("Announcement added successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyError = () => {
    toast.error("Failed to add announcement!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <div className="form-head d-flex mb-3 mb-md-5 align-items-start flex-wrap justify-content-between">
        <div className="me-auto d-lg-block">
          <h3 className="text-primary font-w600">
            Welcome to Apollo Learning Management System!
          </h3>
          <p className="mb-0">Made for Apollo Trainings </p>
        </div>
        <form className="default-search">
          <div className="input-group search-area ms-auto w-100 d-inline-flex">
            <input
              type="text"
              className="form-control"
              placeholder="Search here"
            />
            <span className="input-group-text">
              <i className="flaticon-381-search-2"></i>
            </span>
          </div>
        </form>
      </div>
      <div className="row">
        {/* COUNT CARDS */}
        <div className="col-xl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-primary">
            <div className="card-body  p-4">
              <div className="media">
                <span className="me-3">
                  <i className="la la-users"></i>
                </span>
                <div className="media-body text-white">
                  <p className="mb-1">Employees</p>
                  <h3 className="text-white">{userCounts.nurseCount}</h3>
                  <div className="progress mb-2 bg-secondary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "80%" }}
                    ></div>
                  </div>
                  {/* <small>80% Increase in 20 Days</small> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-secondary">
            <div className="card-body p-4">
              <div className="media">
                <span className="me-3">
                  <i className="la la-graduation-cap"></i>
                </span>
                <div className="media-body text-white">
                  <p className="mb-1">Modules</p>
                  <h3 className="text-white">{userCounts.moduleCount}</h3>
                  <div className="progress mb-2 bg-primary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "76%" }}
                    ></div>
                  </div>
                  {/* <small>76% Increase in 20 Days</small> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-danger ">
            <div className="card-body p-4">
              <div className="media">
                <span className="me-3">
                  <i className=" flaticon-381-blueprint"></i>
                </span>
                <div className="media-body text-white">
                  <p className="mb-1">Quizes</p>
                  <h3 className="text-white">{userCounts.quizCount}</h3>
                  <div className="progress mb-2 bg-secondary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "30%" }}
                    ></div>
                  </div>
                  {/* <small>30% Increase in 30 Days</small> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-sm-6">
          <div className="widget-stat card bg-warning">
            <div className="card-body p-4">
              <div className="media">
                <span className="me-3">
                  <i className="la la-user"></i>
                </span>
                <div className="media-body text-white">
                  <p className="mb-1">Instructors</p>
                  <h3 className="text-white">{userCounts.instructorCount}</h3>
                  <div className="progress mb-2 bg-primary">
                    <div
                      className="progress-bar progress-animated bg-white"
                      style={{ width: "50%" }}
                    ></div>
                  </div>
                  {/* <small>50% Increase in 25 Days</small> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Announcement */}

        <div className="col-xl-4 col-xxl-6 col-lg-6">
          <div className="card border-0 pb-0">
            <div className="card-header border-0 pb-0 d-flex justify-content-between align-items-center">
              <h4 className="card-title">Announcements</h4>
              <button
                onClick={handleAddAnnouncement}
                className="btn btn-primary"
              >
                <i className="flaticon-381-add-1" /> Add
              </button>
            </div>
            <div className="card-body p-0">
              {loading ? (
                <div className="text-center my-4">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <div
                  id="DZ_W_Todo2"
                  className="widget-media dz-scroll height370 my-4 px-4"
                >
                  <ul className="timeline">
                    {announcements.map((announcement) => (
                      <li key={announcement._id}>
                        <div className="timeline-panel">
                          <div className="media me-2">
                            <i className="fa fa-bell"></i>
                          </div>
                          <div className="media-body">
                            <h5 className="mb-1">{announcement.text}</h5>
                            <small className="d-block">
                              {new Date(announcement.date).toLocaleString()}
                            </small>
                          </div>
                          <Dropdown className="dropdown">
                            <Dropdown.Toggle
                              variant="primary light"
                              className="i-false p-0 sharp"
                            >
                              <svg
                                width="18px"
                                height="18px"
                                viewBox="0 0 24 24"
                                version="1.1"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x="0" y="0" width="24" height="24" />
                                  <circle fill="#000000" cx="5" cy="12" r="2" />
                                  <circle
                                    fill="#000000"
                                    cx="12"
                                    cy="12"
                                    r="2"
                                  />
                                  <circle
                                    fill="#000000"
                                    cx="19"
                                    cy="12"
                                    r="2"
                                  />
                                </g>
                              </svg>
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu">
                              <Dropdown.Item
                                onClick={() =>
                                  handleEdit(
                                    announcement._id,
                                    announcement.text
                                  )
                                }
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(announcement._id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>

        {/* CALENDER */}

        <div className="col-xl-4 col-xxl-6 col-lg-6">
          <div className="card bg-danger">
            <div className="card-header border-0 pb-0 justify-content-center">
              <h4 className="card-title text-white">Learning Calendar</h4>
            </div>
            <div className="card-body patient-calender  pb-2">
              <Calendar onChange={onChange} value={value} />
            </div>
          </div>
        </div>


        <div className="col-xl-6 col-xxl-12">
          <div className="row">
            {/* <div className="col-xl-12 col-xxl-12 col-lg-12 col-md-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h4 className="card-title">Module Analytics</h4>
                  <Form.Select className="select-button  style-1 default-select">
                    <option value="1">Module 1</option>
                    <option value="2">Module 2</option>
                    <option value="3">Module 3</option>
                  </Form.Select>
                </div>
                <div className="card-body pt-2">
                  <h3 className="text-primary font-w600">
                    Nurse Learners <small className="text-dark ms-2"></small>
                  </h3>
                  <div id="chartBar">
                    <RevenueChart />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="col-12">
              <Timeline />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
